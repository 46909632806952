@use "@angular/material" as mat;

@include mat.core();

$zetta-meet-primary: mat.define-palette(mat.$indigo-palette);
$zetta-meet-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$zetta-meet-warn: mat.define-palette(mat.$red-palette);

$zetta-meet-theme: mat.define-light-theme(
  (
    color: (
      primary: $zetta-meet-primary,
      accent: $zetta-meet-accent,
      warn: $zetta-meet-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

@include mat.all-component-themes($zetta-meet-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply h-full;
}
body {
  @apply m-0 font-sans;
}

#jaas-container {
  height: 100vh;
  width: auto;
}

.material-icons-round {
  font-family: "Material Icons Round";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: "liga";
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
